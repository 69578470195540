<template>
  <v-card>
    <DeleteDialog
      :dialog="dialog"
      v-on:confirm-delete="deleteItem"
      v-on:cancel-dialog="dialog = false"
    />
    <v-data-table
      v-if="companyApps"
      :headers="headers"
      :items="companyApps"
      :items-per-page="50"
      item-key="applicationId"
      class="elevation-1"
      @click:row="clickedRow"
    >
      <template v-slot:[`item.createdAt`]="{ item }">
        {{ humanDate(item.createdAt, "dd MMM yyyy - HH:mm") }}
      </template>
      <template
        v-slot:[`item.action`]="{ item }"
        v-if="permitted('Application.Delete')"
      >
        <v-icon dark color="error" @click.stop="confirmDelete(item)"
          >delete</v-icon
        >
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapActions, mapState } from "vuex";

import { Roles } from "@/_helpers/Role";
import DeleteDialog from "@/components/common/DeleteDialog";

export default {
  name: "CompanyApps",
  data() {
    return {
      dialog: false,
      currentItem: {},
      expanded: [],
      headers: [
        { text: this.$t("application.fields.id"), value: "applicationId" },
        { text: this.$t("application.fields.name"), value: "name" },
        { text: this.$t("application.fields.createdAt"), value: "createdAt" },
        { text: "", value: "action", sortable: false },
      ],
    };
  },

  computed: {
    ...mapState("users", ["currentUser", "userCompany", "hasCompany"]),
    ...mapState("companies", ["status", "companyApps"]),
    ...mapState("applications", { applicationStatus: "status" }),

    Roles() {
      return Roles;
    },
  },

  methods: {
    ...mapActions("companies", ["getCompanyApplications"]),
    ...mapActions("applications", ["deleteApplication"]),

    clickedRow(item) {
      if (this.permitted("Application.Update"))
        this.$router.push(`/application/${item.applicationId}`);
    },

    async deleteItem() {
      this.deleting = true;
      this.deleteApplication({
        applicationId: this.currentItem.applicationId,
      });
      this.deleting = false;
      this.dialog = false;
    },

    async confirmDelete(item) {
      this.currentItem = item;
      this.dialog = true;
    },
  },

  created() {
    this.getCompanyApplications();
  },
  components: {
    DeleteDialog,
  },
};
</script>

<style></style>
